// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bw_k4";
export var caseStudyBackground__bgColor = "bw_kV";
export var caseStudyBackground__lineColor = "bw_kW";
export var caseStudyCta__bgColor = "bw_l0";
export var caseStudyHead__imageWrapper = "bw_kT";
export var caseStudyProjectsSection = "bw_k5";
export var caseStudyQuote__bgLight = "bw_k2";
export var caseStudyQuote__bgRing = "bw_k1";
export var caseStudyTechSection = "bw_lb";
export var caseStudyVideo = "bw_k7";
export var caseStudyVideo__ring = "bw_k8";
export var caseStudy__bgDark = "bw_kS";
export var caseStudy__bgLight = "bw_kR";